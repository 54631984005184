import React, {useState} from 'react';

function Banner() {
  return (
    <div style={{backgroundColor:'#fff8c5', paddingTop: '15px', paddingBottom: '15px', textAlign:'center', color:'#202328'}}>
        🇺🇦 On 24 February 2022, russia invaded Ukraine. The aggressor has killed many thousands of people, committed other vicious crimes, and occupied land. Support Ukrainian army on <a href={"https://savelife.in.ua/en/"} target={"_blank"}> savelife.in.ua</a>. Evil cannot prevail.
    </div>
  )
}

export default Banner;
