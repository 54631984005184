import React from 'react';
import {IconProps} from '../Icon'

function CCNAIcon(props: IconProps) {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="300" height="300" viewBox="0 0 300 300"
 preserveAspectRatio="xMidYMid meet">
<rect width="300" height="300" fill="white"/>
<g transform="translate(0.000000,300) scale(0.100000,-0.100000)"
fill="#000" stroke="none">
<path d="M315 2389 c-81 -90 -111 -211 -102 -414 19 -404 171 -783 422 -1052
88 -95 118 -111 48 -25 -190 233 -317 534 -359 856 -21 155 -14 404 15 561 11
60 19 111 17 112 -2 2 -20 -15 -41 -38z"/>
<path d="M2644 2405 c63 -302 59 -606 -9 -870 -61 -232 -175 -462 -318 -637
-70 -86 -40 -70 48 25 251 269 402 646 422 1052 10 207 -26 343 -112 425 l-37
35 6 -30z"/>
<path d="M1033 2355 c-50 -35 -50 -135 0 -170 33 -23 90 -19 115 8 22 23 30
57 14 57 -5 0 -18 -13 -30 -30 -26 -35 -50 -38 -83 -11 -18 14 -24 29 -24 59
0 75 62 107 109 57 27 -29 36 -31 36 -10 0 47 -90 73 -137 40z"/>
<path d="M1230 2270 c0 -82 3 -100 15 -100 12 0 15 18 15 100 0 82 -3 100 -15
100 -12 0 -15 -18 -15 -100z"/>
<path d="M1337 2352 c-33 -37 -13 -69 55 -91 48 -16 65 -36 48 -56 -19 -23
-58 -18 -84 10 -28 30 -46 32 -39 5 13 -49 105 -68 143 -30 40 40 20 74 -62
100 -44 14 -54 26 -38 45 18 21 58 18 72 -5 15 -23 38 -27 38 -7 0 44 -101 65
-133 29z"/>
<path d="M1549 2344 c-19 -20 -24 -36 -24 -76 0 -42 4 -53 28 -74 40 -34 98
-33 126 2 25 31 27 54 6 54 -8 0 -15 -6 -15 -13 0 -19 -33 -47 -54 -47 -27 0
-66 46 -66 78 0 38 35 82 65 82 24 0 55 -23 55 -41 0 -5 7 -9 15 -9 21 0 19
26 -5 50 -31 31 -99 27 -131 -6z"/>
<path d="M1775 2345 c-36 -35 -35 -112 1 -149 59 -58 164 -14 164 69 0 69 -33
104 -98 105 -32 0 -49 -6 -67 -25z m114 -21 c12 -15 21 -39 21 -54 0 -15 -9
-39 -21 -54 -42 -53 -109 -20 -109 54 0 74 67 107 109 54z"/>
<path d="M735 2088 c-32 -28 -43 -85 -25 -129 27 -64 131 -73 159 -13 14 32
14 34 -2 34 -8 0 -19 -11 -25 -25 -16 -34 -74 -36 -96 -3 -24 34 -20 85 9 113
31 32 50 31 81 0 14 -14 29 -25 34 -25 17 0 11 20 -15 45 -32 33 -84 33 -120
3z"/>
<path d="M940 2005 l0 -95 60 0 c71 0 81 18 13 22 -46 3 -48 4 -48 33 0 28 3
30 43 33 23 2 42 7 42 12 0 5 -19 10 -42 12 -39 3 -43 6 -46 31 -3 26 -2 27
47 27 28 0 51 5 51 10 0 6 -27 10 -60 10 l-60 0 0 -95z"/>
<path d="M1120 2005 c0 -78 3 -95 15 -95 11 0 15 11 15 40 0 36 3 40 25 40 19
0 29 -10 45 -40 11 -22 27 -40 35 -40 19 0 19 3 -4 48 -19 38 -19 38 0 50 10
7 19 23 19 36 0 41 -25 56 -91 56 l-59 0 0 -95z m110 60 c21 -26 4 -45 -40
-45 -38 0 -40 2 -40 30 0 27 3 30 34 30 18 0 39 -7 46 -15z"/>
<path d="M1310 2090 c0 -5 14 -10 30 -10 l30 0 0 -85 c0 -69 3 -85 15 -85 12
0 15 16 15 85 l0 85 35 0 c19 0 35 5 35 10 0 6 -33 10 -80 10 -47 0 -80 -4
-80 -10z"/>
<path d="M1520 2005 c0 -57 4 -95 10 -95 6 0 10 38 10 95 0 57 -4 95 -10 95
-6 0 -10 -38 -10 -95z"/>
<path d="M1610 2005 c0 -78 3 -95 15 -95 11 0 15 12 15 45 l0 45 40 0 c22 0
40 5 40 10 0 6 -18 10 -40 10 -38 0 -40 2 -40 30 0 29 2 30 45 30 25 0 45 5
45 10 0 6 -27 10 -60 10 l-60 0 0 -95z"/>
<path d="M1790 2005 c0 -78 3 -95 15 -95 12 0 15 17 15 95 0 78 -3 95 -15 95
-12 0 -15 -17 -15 -95z"/>
<path d="M1880 2005 l0 -95 65 0 c37 0 65 4 65 10 0 6 -22 10 -50 10 l-50 0 0
35 c0 35 0 35 45 35 25 0 45 5 45 10 0 6 -20 10 -45 10 -43 0 -45 1 -45 30 0
29 1 30 50 30 28 0 50 5 50 10 0 6 -28 10 -65 10 l-65 0 0 -95z"/>
<path d="M2070 2005 l0 -95 55 0 c46 0 60 4 80 25 33 32 35 100 4 139 -18 23
-28 26 -80 26 l-59 0 0 -95z m108 59 c16 -11 22 -25 22 -53 0 -49 -17 -70 -63
-78 l-37 -6 0 77 0 76 28 0 c15 0 38 -7 50 -16z"/>
<path d="M98 1970 c-34 -51 -51 -107 -56 -190 -7 -136 47 -329 154 -541 107
-214 281 -416 463 -538 95 -63 190 -115 197 -109 2 2 -31 29 -73 59 -102 72
-256 225 -331 327 -190 259 -301 570 -330 927 -7 89 -8 90 -24 65z"/>
<path d="M2876 1895 c-23 -337 -139 -660 -328 -917 -75 -102 -229 -255 -331
-327 -42 -30 -75 -57 -73 -59 7 -6 102 46 197 109 280 188 503 520 595 889 33
128 30 243 -6 327 -38 89 -46 85 -54 -22z"/>
<path d="M727 1650 c-44 -13 -95 -57 -119 -103 -18 -34 -23 -58 -23 -128 0
-78 3 -90 30 -134 44 -72 98 -97 195 -93 59 2 82 8 108 26 37 27 72 88 72 127
0 21 -5 25 -28 25 -25 0 -30 -6 -41 -39 -35 -118 -193 -124 -252 -10 -28 56
-25 173 7 215 71 96 195 96 241 -1 13 -28 23 -35 45 -35 23 0 28 4 28 24 0 37
-41 89 -90 114 -46 23 -119 28 -173 12z"/>
<path d="M1175 1642 c-85 -41 -125 -111 -125 -222 0 -150 75 -230 215 -230 70
0 106 13 142 52 24 25 53 88 53 113 0 22 -59 20 -66 -2 -27 -89 -67 -118 -148
-110 -41 4 -55 11 -82 40 -53 58 -67 136 -38 220 29 85 107 124 189 96 31 -11
44 -23 60 -56 17 -37 24 -42 53 -43 38 0 39 9 11 66 -28 59 -74 86 -157 91
-52 3 -76 -1 -107 -15z"/>
<path d="M1540 1425 l0 -225 25 0 25 0 2 188 3 187 113 -187 113 -188 40 0 39
0 0 225 0 225 -25 0 -25 0 0 -181 c0 -99 -4 -178 -9 -175 -4 3 -55 84 -112
180 l-104 175 -42 1 -43 0 0 -225z"/>
<path d="M2052 1431 c-51 -120 -92 -222 -92 -225 0 -3 14 -6 30 -6 28 0 33 6
57 60 l26 60 102 0 102 0 26 -60 c25 -57 28 -60 61 -60 l34 0 -90 225 -90 225
-37 0 -37 0 -92 -219z m146 104 c5 -16 21 -60 35 -97 l26 -68 -83 0 -84 0 41
104 c23 58 45 102 48 98 4 -4 11 -20 17 -37z"/>
</g>
</svg>
  )
}

export default CCNAIcon;
